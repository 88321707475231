import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { navigate } from "@reach/router";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// images
import AvatarImage from "../img/share/profile/avatar.png";
import ChartImagePC from "../img/share/profile/chart_pc.svg";
import ChartImageSP from "../img/share/profile/chart_sp.svg";

export const ShareParsOfTroublePageTemplate = ({
  content,
  contentComponent
}) => {
  return (
    <>
      <section className="l-section-lv2 l-main-content-hero u-pt00">
        <div className="l-main-content-hero__inner">
          <div className="c-label-box-tile">
            <div className="c-label-box-tile__inner">
              <h3 className="c-label-box-tile__title">-お肌のお悩み-</h3>
              <div className="c-label-box-tile__list">
                <div className="c-label-box-tile__list__item c-border-label">
                  <Link className="c-border-label__link" to="/chloasma/ranking">
                    <span className="c-border-label__link__text">
                      大きなしみ(老人性色素斑等)
                    </span>
                  </Link>
                </div>
                <div className="c-label-box-tile__list__item c-border-label">
                  <Link className="c-border-label__link" to="/freckles/ranking">
                    <span className="c-border-label__link__text">
                      小さなしみ(雀卵斑等)
                    </span>
                  </Link>
                </div>
                <div className="c-label-box-tile__list__item c-border-label">
                  <Link className="c-border-label__link" to="/dullness/ranking">
                    <span className="c-border-label__link__text">
                      肝斑、くすみ
                    </span>
                  </Link>
                </div>
                <div className="c-label-box-tile__list__item c-border-label">
                  <Link
                    className="c-border-label__link"
                    to="/slack-and-lift-up/ranking"
                  >
                    <span className="c-border-label__link__text">
                      たるみ・リフトアップ
                    </span>
                  </Link>
                </div>
                <div className="c-label-box-tile__list__item c-border-label">
                  <Link className="c-border-label__link" to="/gap-pore/ranking">
                    <span className="c-border-label__link__text">
                      毛穴の開き
                    </span>
                  </Link>
                </div>
                <div className="c-label-box-tile__list__item c-border-label">
                  <Link className="c-border-label__link" to="/wrinkle/ranking">
                    <span className="c-border-label__link__text">しわ</span>
                  </Link>
                </div>

                {/* <div className="c-label-box-tile__list__item c-border-label">
                  <Link className="c-border-label__link" to="/acne/ranking">
                    <span className="c-border-label__link__text">ニキビ</span>
                  </Link>
                </div>
                <div className="c-label-box-tile__list__item c-border-label">
                  <Link className="c-border-label__link" to="/">
                    <span className="c-border-label__link__text">ニキビ跡</span>
                  </Link>
                </div>
                <div className="c-label-box-tile__list__item c-border-label">
                  <Link className="c-border-label__link" to="/">
                    <span className="c-border-label__link__text">多汗症</span>
                  </Link>
                </div>
                <div className="c-label-box-tile__list__item c-border-label">
                  <Link className="c-border-label__link" to="/">
                    <span className="c-border-label__link__text">痩身・ダイエット</span>
                  </Link>
                </div>
                <div className="c-label-box-tile__list__item c-border-label">
                  <Link className="c-border-label__link" to="/">
                    <span className="c-border-label__link__text">AGA・女性脱毛症</span>
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

ShareParsOfTroublePageTemplate.propTypes = {
  title: PropTypes.string
};

const ShareParsOfTroublePage = ({ data }) => {
  // const { markdownRemark: post } = data;

  return <ShareParsOfTroublePageTemplate />;
};

ShareParsOfTroublePage.propTypes = {
  data: PropTypes.object
};

export default ShareParsOfTroublePage;

export const sharePartsOfTroublePageQuery = graphql`
  query ShareParsOfTroublePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
    }
  }
`;
