import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// images
import AvatarImage from "../img/share/profile/avatar.png";
import AvatarImage002 from "../img/share/profile/avatar_002.webp";
import ChartImagePC from "../img/share/profile/chart_pc.svg";
import ChartImageSP from "../img/share/profile/chart_sp.svg";

export const ShareHistoryPageTemplate = ({ content, contentComponent }) => {
  return (
    <>
      <section className="l-section-lv2 l-main-content-hero">
        <div className="l-main-content-hero__inner">
          <div className="c-h2-title">
            <div className="c-h2-title__inner">
              <h2 className="c-h2-title__text">-CAREER-</h2>
              <p className="c-h2-title__description">院長の経歴</p>
            </div>
          </div>

          <div className="c-profile-media u-pc-mb30">
            <div className="c-profile-media__inner">
              <div className="c-profile-media__col">
                <div className="">
                  <img src={AvatarImage002} alt="" />
                </div>
              </div>
              <div className="c-profile-media__col">
                <div className="c-profile-media__title">
                  {/* <p className="c-profile-media__sub-text">
                    医療法人ひまわり会
                  </p> */}
                  <p className="c-profile-media__main-text">院長　吉森　千夏</p>
                </div>
                <p className="c-profile-media__description">
                  2010年　日本大学医学部卒業
                  <br />
                  2015年　日本大学病院　医局長　外来医長
                  <br />
                  2023年　天神皮ふ科　院長
                </p>
              </div>
            </div>
          </div>

          <div className="c-profile-media">
            <div className="c-profile-media__inner">
              <div className="c-profile-media__col">
                <div className="c-profile-media__image">
                  <img src={AvatarImage} alt="" />
                </div>
              </div>
              <div className="c-profile-media__col">
                <div className="c-profile-media__title">
                  <p className="c-profile-media__sub-text">
                    医療法人ひまわり会
                  </p>
                  <p className="c-profile-media__main-text">
                    理事長　松尾　眞二郎
                  </p>
                </div>
                <p className="c-profile-media__description">
                  こんにちは、「天神皮ふ科」理事長の松尾眞二郎です。2009年4月に中野皮膚科を継承しました。患者数の増加、医療機器の増設に伴いより広い診療スペースの確保のため2011年12月に現在のところに移転しました。私は福岡市で生まれ育ち、1991年10月に金沢医科大学を卒業。そして卒業後は九州大学皮膚科、原三信病院、国立福岡中央病院（現、九州医療センター）で研修を受けました。
                  所属医学会：日本皮膚科学会、日本美容皮膚科学会、日本抗加齢医学会
                </p>
              </div>
            </div>
            <div className="c-profile-media__full-capture">
              <img className="u-sp-dis-n" src={ChartImagePC} alt="" />
              <img className="u-pc-dis-n" src={ChartImageSP} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

ShareHistoryPageTemplate.propTypes = {
  title: PropTypes.string,
};

const ShareHistoryPage = ({ data }) => {
  // const { markdownRemark: post } = data;

  return <ShareHistoryPageTemplate />;
};

ShareHistoryPage.propTypes = {
  data: PropTypes.object,
};

export default ShareHistoryPage;

export const shareHistoryPageQuery = graphql`
  query ShareHistoryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
    }
  }
`;
